import React, {ReactElement, useEffect, useState} from "react";

import {useTrainingSetUpload} from "../../../hooks/useTrainingSetUpload";
import {GET_TRAINING_SET, GET_TRAINING_SETS} from "../../../graphql/queries/ai-models-queries";
import {MAX_DOCUMENT_SIZE, MAX_VIDEO_SIZE, UploadFilesModal} from "../../../shared/v2/modals/upload-files-modal";
import {Body, Button, Dropdown} from "../../../shared/v2";
import {FileUploadIcon, GoogleDriveIcon, PlusSmallIcon} from "../../../icons";
import useDrivePicker from "react-google-drive-picker";
import {useToastContext} from "../../../context/toast-context";
import {CallbackDoc} from "react-google-drive-picker/dist/typeDefs";
import config from "../../../config";
import {TrainingSet} from "../../../models/ai-model";
import styles from "./file-uploader.module.scss";

export interface FileUploaderProps {
	trainingSet: TrainingSet;
	refetchQuery: typeof GET_TRAINING_SET | typeof GET_TRAINING_SETS;
  startPolling?: () => void;
	isModalOpen?: boolean;
	setIsModalOpen?: (isOpen: boolean) => void;
}

export const FileUploader = ({
	trainingSet,
	refetchQuery,
	startPolling,
}: FileUploaderProps): ReactElement => {
	const {
		uploadFile,
		files,
		setFiles,
		createTrainingSetFile,
		addFileToTrainingSet,
		uploadFileInProgress,
		isCreatingFileOrVideo,
		isAddingFileOrVideoToTrainingSet,
	} = useTrainingSetUpload(refetchQuery, trainingSet, startPolling);
	const {updateToast} = useToastContext();
	const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
	const [gDriveDocuments, setGDriveDocuments] = React.useState<CallbackDoc[]>([]);

	const [openPicker, authResponse] = useDrivePicker();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const saveUploadedFiles = async() :Promise<any> => {
		updateToast({
			description: `Adding ${files.length} ${files.length === 1 ? "file" : "files"} in progress`,
			type: "informational",
		});

		const addPromises = files.map(async file => {
			const trainingSetFileData = await createTrainingSetFile(
				file.uploadResult,
				trainingSet.id,
			);

			if (trainingSetFileData.data) {
				await addFileToTrainingSet(trainingSetFileData.data.createTrainingSetFile ||
        trainingSetFileData.data.createTrainingSetVideo, trainingSet.id, file.labels);
			}
		});
		await Promise.all(addPromises);
	};

	const handleOpenUploadPicker = () :void => {
		setIsUploadModalOpen(true);
	};

	const handleClose = () :void => {
		setIsUploadModalOpen(false);
	};

	const uploadGDriveFiles = async(file: File): Promise<void> => {
		const uploadResult = await uploadFile({
			variables: {
				file,
			},
		});
		if (uploadResult.data) {

			const trainingSetFileData = await createTrainingSetFile(uploadResult.data.upload, trainingSet.id);
			if (trainingSetFileData.data) {
				await addFileToTrainingSet(trainingSetFileData.data.createTrainingSetFile ||
						trainingSetFileData.data.createTrainingSetVideo, trainingSet.id);
			}
		}
	};

	useEffect(() => {
		if (authResponse && gDriveDocuments.length > 0) {
			gDriveDocuments.forEach(document => {
				const xhr = new XMLHttpRequest();
				xhr.open("GET", `https://www.googleapis.com/drive/v3/files/${document.id}?alt=media`);
				xhr.setRequestHeader(
					"Authorization",
					`Bearer ${authResponse.access_token}`,
				);

				xhr.responseType = "blob";

				xhr.onload = function() {
					const blob = xhr.response;
					const file = new File([blob], document.name, {type: document.mimeType});

					uploadGDriveFiles(file);
				};
				xhr.onerror = function() {
					updateToast({
						description: "There was an error while loading the files from Google Drive",
						type: "failure",
					});
				};
				xhr.send();

				setGDriveDocuments([]);
			});
		}
	}, [authResponse, gDriveDocuments]);

	const handleOpenPicker = ():void => {
		openPicker({
			clientId: config.googleApiConfig.clientId,
			developerKey: config.googleApiConfig.developerKey,
			viewId: "DOCS",
			showUploadView: true,
			showUploadFolders: true,
			supportDrives: true,
			multiselect: true,
			viewMimeTypes: "video/*,text/plain,text/csv,text/markdown,application/json,application/pdf,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/*",
			callbackFunction: data => {
				if (data.docs) {
					const docs = data.docs;
					const largeFiles = docs.filter(doc => {
						const isVideo = doc.mimeType.startsWith("video/");
						const maxSize = isVideo ? MAX_VIDEO_SIZE : MAX_DOCUMENT_SIZE;
						return doc.sizeBytes > maxSize;
					});
					const validFiles = docs.filter(doc => {
						const isVideo = doc.mimeType.startsWith("video/");
						const maxSize = isVideo ? MAX_VIDEO_SIZE : MAX_DOCUMENT_SIZE;
						return doc.sizeBytes <= maxSize;
					});

					if (largeFiles.length > 0) {
						updateToast({
							description: <div>
								The following files are too large to upload:
								<ul>
									{largeFiles.map(doc => <li key={doc.id} className={styles.listItem}>{doc.name}</li>)}
								</ul>
								Please try again with files smaller than the allowed size. 50MB for files and 100MB for videos.

								{validFiles.length > 0 && <Body color="gray-modern-25">The rest {validFiles.length} files will be uploaded.</Body>}
							</div>,
							type: "failure",
							displayTime: 20000
						});
					} else {
						updateToast({
							description: `Upload ${validFiles.length}
								${validFiles.length === 1 ? "file" : "files"} in progress`,
							type: "informational",
							displayTime: 20000
						});
					}
					if (validFiles.length > 0) {
						setGDriveDocuments(validFiles);
					}
				}
			},
		});
	};

	return <>
		<Dropdown
			trigger={<Button.Dummy size="small" variant="outlined" ariaLabel = "menu-button" leftIcon={<PlusSmallIcon />}>Add files</Button.Dummy>}
			position="bottom-start"

			items={[
				{
					label: "Upload",
					icon: <FileUploadIcon />,
					onClick: handleOpenUploadPicker,
					disabled: isCreatingFileOrVideo ||
          isAddingFileOrVideoToTrainingSet,
				},
				{
					label: "Add via Google Drive",
					icon: <GoogleDriveIcon />,
					onClick: handleOpenPicker,
					disabled: uploadFileInProgress || isCreatingFileOrVideo ||
            isAddingFileOrVideoToTrainingSet,

				},
			]}
		/>

		<UploadFilesModal
			files={files}
			setFiles={setFiles}
			isOpen={isUploadModalOpen}
			onClose={handleClose}

			onFinish={saveUploadedFiles}
			clearOnFinish={true}
		/>
	</>;
};
