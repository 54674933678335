import React, {FormEvent, HTMLProps, ReactElement} from "react";
import classNames from "classnames/bind";

import {InputElements} from "../input-elements";
import {UseValidationResult} from "../../../../hooks/useValidation";
import {useThemeMode} from "../../../../context/theme-mode-context";
import {FullScreenExpandIcon} from "../../../../icons";

import styles from "../input/input.module.scss";

const cx = classNames.bind(styles);

export type TextAreaProps = Omit<HTMLProps<HTMLTextAreaElement>, "size" | "value" | "onChange"> & {
	variant?: "default" | "error",
	size?: "small" | "medium"
	label?: string;
	hintText?: string;
	onChange: (value: string) => void;
	validation?: UseValidationResult;
	value: string;
  isExpandable?: boolean;
	refProp?: any;
}

export const TextArea = ({
	value,
	onChange,
	className,
	size = "small",
	variant = "default",
	label,
	hintText,
	validation,
	isExpandable,
	refProp,
	...props
}: TextAreaProps): ReactElement => {
	const {isDarkMode} = useThemeMode();

	return <InputElements
		variant={variant}
		className={className}
		label={label}
		hintText={hintText}
		validation={validation}
	>
		<>
			<textarea
				className={cx(
					"input",
					"textarea",
					size,
					validation?.isError ? "error" : variant,
					{isExpandable}
				)}
				onWheel={(e) => {
					const isOverflown = e.currentTarget.scrollHeight > e.currentTarget.clientHeight;
					if (isOverflown) {
						e.stopPropagation();
					}
				}}
				value={value}
				onChange={(e: FormEvent<HTMLTextAreaElement>) => onChange(e.currentTarget.value)}
				ref={refProp}
				{...props}
			/>
			{isExpandable && <FullScreenExpandIcon className={cx("expandIcon", {isDarkMode})} />}
		</>
	</InputElements>
}
