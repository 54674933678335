import {LayoutContainer} from '@/shared';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import {useMutation, useQuery} from '@apollo/client';

import {Body, Button, Dropdown, Header, LoadingContainer} from '@/shared/v2';
import {BinDeleteIcon, DotsIcon, PenEditIcon, PlusIcon} from '@/icons';
import {useWorkspaceContext} from '@/context/workspace-context';
import {FILE_TAG_KEYS} from '@/graphql/queries/ai-models-queries';
import {Column} from 'react-table';
import {Key} from '@/datasets/components/manage-labels-modal/label-form';
import {Table} from '@/shared/components/table';
import {useMount} from '@/hooks/useMount';
import {AlignText} from '@/shared/typography/align-text';
import {FILE_TAG_KEY_CREATE_BATCH, FILE_TAG_KEY_DELETE_BATCH, FILE_TAG_KEY_UPDATE} from '@/graphql/mutations/ai-mutations';
import {useToastContext} from '@/context/toast-context';
import {AddKeysModal} from '@/workspace-settings/components/manage-keys/add-keys-modal';
import {ConfirmActionModal} from '@/shared/v2/modals/confirm-action-modal';
import {EditKeyModal} from '@/workspace-settings/components/manage-keys/edit-key-modal';

import styles from './manage-keys.module.scss';
import {useNavigate} from 'react-router';

export const ManageKeys = (): ReactElement => {
	const {updateToast} = useToastContext();
  const {workspace: {id: workspaceId}, isWokspaceAdmin} = useWorkspaceContext();
  const navigate = useNavigate();

  const [isAddKeysModalOpen, setIsAddKeysModalOpen] = useState(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);
  const [keyToDelete, setKeyToDelete] = useState<Key | null>(null);
  const [isEditKeysModalOpen, setIsEditKeysModalOpen] = useState(false);
  const [keyToEdit, setKeyToEdit] = useState<Key | null>(null);
	const {data: keysData, loading: keysLoading} = useQuery(FILE_TAG_KEYS, {
		variables: {
			workspaceId
		}
	});

  const [deleteKeys] = useMutation(FILE_TAG_KEY_DELETE_BATCH, {refetchQueries: [FILE_TAG_KEYS]});
  const [addKeys] = useMutation(FILE_TAG_KEY_CREATE_BATCH, {refetchQueries: [FILE_TAG_KEYS]});
  const [updateKey] = useMutation(FILE_TAG_KEY_UPDATE)

  useEffect(() => {
		if (!isWokspaceAdmin) {
			updateToast({
				description: "You do not have access to this page",
				type: "failure"
			})
			navigate("/");
		}
	}, [isWokspaceAdmin]);

  useMount(() => {document.title = "Vurvey - Manage Keys"})

  const handleDelete = () => {
    deleteKeys({
      variables: {
        ids: [keyToDelete?.id]
      },
      onCompleted: () => {
        updateToast({
          description: "Key deleted",
          type: "success"
        })
        setIsDeleteConfirmationModalOpen(false);
        setKeyToDelete(null);
      },
      onError: () => {
        updateToast({
          description: "Failed to delete key. This key is used in some file or video and cannot be deleted",
          type: "failure"
        })
      }
    })
  }

  const handleAdd = (keys: string[]) => {
    addKeys({
      variables: {
        workspaceId,
        values: keys
      },
      onCompleted: () => {
        updateToast({
          description: "Keys added successfully",
          type: "success"
        })
      },
      onError: () => {
        updateToast({
          description: "Failed to add keys (Check for duplicates)",
          type: "failure"
        })
      }
    })
  }

  const handleEdit = (key: string) => {
    updateKey({
      variables: {
        id: keyToEdit?.id,
        value: key
      },
      onCompleted: () => {
        setIsEditKeysModalOpen(false)
        setKeyToEdit(null)
        updateToast({
          description: "Key updated",
          type: "success"
        })
      },
      onError: () => {
        updateToast({
          description: "Failed to update key (Check for duplicates)",
          type: "failure"
        })
      }
    })
  }

  const columns = useMemo((): Column<Key>[] => {
		return [
			{
				id: "value",
				Header: "Value",
				accessor: "value",
				Cell: ({value}) => <Body size="s">{value}</Body>
			},
      {
        id: "options",
        Header: "",
        maxWidth: 20,
        disableSortBy: true,
        Cell: data => {
          return <AlignText align="right">
            <Dropdown
              position="bottom-start"
              trigger={<DotsIcon className={styles.trigger} />}
              items={[
                {
                  label: "Edit",
                  onClick: () => {
                    setKeyToEdit(data.row.original)
                    setIsEditKeysModalOpen(true);
                  },
                  icon: <PenEditIcon />
                },
                {
                  label: "Delete",
                  onClick: () => {
                    setKeyToDelete(data.row.original)
                    setIsDeleteConfirmationModalOpen(true)
                  },
                  color: "danger",
                  icon: <BinDeleteIcon />
                },
              ]}
            />
          </AlignText>;
        },
      },
		];
	}, [keysData]);

  const renderTable = () => {
    if (keysLoading) {
      return <LoadingContainer />
    }

    return <Table
      columns={columns}
      data={keysData?.fileTagKeys}
      className={styles.table}
    />
  }

  return <>
    <LayoutContainer theme="dark">
      <div className={styles.container}>
        <header className={styles.header}>
          <Header type="medium">Manage Keys</Header>

          <Button
            onClick={() => setIsAddKeysModalOpen(true)}
            leftIcon={<PlusIcon />}
          >
            Add Keys
          </Button>
        </header>

        {renderTable()}
      </div>
    </LayoutContainer>

    <AddKeysModal
      onSave={handleAdd}
      isOpen={isAddKeysModalOpen}
      onClose={() => setIsAddKeysModalOpen(false)}
    />

    <EditKeyModal
      isOpen={isEditKeysModalOpen}
      onClose={() => {
        setIsEditKeysModalOpen(false)
        setKeyToEdit(null)
      }}
      onSave={handleEdit}
      editedKey={keyToEdit?.value}
    />

    <ConfirmActionModal
      title="Are you sure you want to delete this key?"
      description="This key will be permanently deleted and cannot be recovered. Are you sure?"
      isOpen={isDeleteConfirmationModalOpen}
      onClose={() => {
        setIsDeleteConfirmationModalOpen(false)
        setKeyToDelete(null)
      }}
      onConfirm={() => {
        handleDelete();
      }}
      confirmText="Delete"
      cancelText="Cancel"
			/>
  </>
}
