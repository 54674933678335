import React, { ReactElement, useMemo } from 'react';
import {useQuery} from '@apollo/client';

import {Body, Button, Input, Spinner} from '@/shared/v2';
import {PlusSmallIcon} from '@/icons';
import {LabelForm} from '../label-form';
import {useWorkspaceContext} from '@/context/workspace-context';
import {FILE_TAG_KEYS} from '@/graphql/queries/ai-models-queries';

import styles from './labels-form.module.scss';

export interface Label {
  key: string;
  value: string;
}

export interface LabelsFormProps {
  labels?: Label[];
  setLabels: (labels: Label[]) => void;
}

export const LabelsForm = ({labels, setLabels}: LabelsFormProps): ReactElement => {
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const {data: keysData, loading: keysLoading} = useQuery(FILE_TAG_KEYS, {
		variables: {
			workspaceId
		}
	});

  const removeLabel = (label: Label) => {
    if (!labels) {
      return;
    }
    setLabels(labels?.filter(l => l !== label));
  }

  const addLabel = () => {
    if (!labels) {
      setLabels([{key: '', value: ''}]);
      return;
    }
    setLabels([...labels, {key: '', value: ''}]);
  }

  const updateLabel = (index: number, updatedLabel: Label) => {
    if (!labels) {
      return;
    }
    const updatedLabels = [...labels];
    updatedLabels[index] = updatedLabel;
    setLabels(updatedLabels);
  };

  const noKeysInWorkspace = useMemo(() => {
    return keysData?.fileTagKeys.length === 0;
  }, [keysData?.fileTagKeys]);

  const renderLabels = () => {
    if (keysLoading) {
      return <div className={styles.spinnerWrapper}>
          <Spinner className={styles.spinner} />
        </div>
    }

    if (noKeysInWorkspace) {
      return <Body>There is no keys in your workspace. Contact your workspace admin to add keys.</Body>;
    }

    return <>
    <div className={styles.headers}>
      <Body type='medium'>Key</Body>
      <Body type='medium'>Value</Body>
      <div className={styles.empty}></div>
    </div>
    {labels?.map((label, index) => <LabelForm
      key={index}
      number={index +1}
      onRemove={() => removeLabel(label)}
      label={label}
      onUpdate={(updatedLabel) => updateLabel(index, updatedLabel)}
      allKeys={keysData?.fileTagKeys}
      />)}
    </>
  };
  return <div className={styles.labelsSection}>
    {renderLabels()}
    <Button
      onClick={addLabel}
      leftIcon={<PlusSmallIcon />}
      variant='text'
      className={styles.addButton}
      disabled={noKeysInWorkspace}
    >
      Add label
    </Button>
  </div>
}
