/* eslint-disable react/prop-types */
import classNames from "classnames/bind";
import React, {ReactElement, useCallback, useEffect, useState} from "react";

import {Column, IdType, Row, useFlexLayout, useGlobalFilter, useMountedLayoutEffect, usePagination, useRowSelect, useSortBy, useTable} from "react-table";
import {TablePagination} from "./table-pagination";
import {matchSorter} from "match-sorter";
import {useThemeMode} from "../../../context/theme-mode-context";
import {Body, Button, Caption, Checkbox} from "../../v2";

import styles from "./table.module.scss";
import {ArrowDownIcon, ArrowUpIcon} from "@/icons";

const bStyles = classNames.bind(styles);

export interface TableProps<T extends object> {
	columns: readonly Column<T>[];
	data: T[];
	editableRowIndex?: number;
	selectedValues?: Row<T>[];
	onSelectChange?: (rows: Row<T>[]) => void;
	searchValue?: string
	filters?: string[];
	className?: string;
}

const PAGE_SIZE = 100;
/**
 * This is a pure front end table, meaning this expects to get all available data
 * and not make any further hits to the backend whenever anything is clicked.
 *
 * This automatically paginates itself with the base size of 100
 *
 * Although with outside filters, we can make it work while hitting backend (most likely)
 */
const Table = <T extends object>({
	columns,
	data,
	editableRowIndex,
	onSelectChange,
	searchValue,
	selectedValues,
	filters,
	className,
}: TableProps<T>): ReactElement => {
	/**
	 * Custom filter function
	 */
	const globalFilterFunction =
	useCallback((rows: Row<T>[], ids: IdType<T>[], query: string): Row<T>[] => {
		if (!filters) return rows;
		return matchSorter(rows, query, {keys: filters?.map(name => `values.${name}`)});
	}, [filters]);
	const [allSelected, setAllSelected] = useState(false);

	const {isDarkMode} = useThemeMode();

	const handleUncheckBox = useCallback((e: React.ChangeEvent<HTMLInputElement>, f?: ((e: React.ChangeEvent<HTMLInputElement>) => void)): void => {
		setAllSelected(false);
		f?.(e);
	}, []);

	const {
		getTableProps,
		headerGroups,
		page,
		prepareRow,
		selectedFlatRows,
		toggleAllRowsSelected,
		setGlobalFilter,
		pageCount,
		state: {pageIndex, pageSize},
		gotoPage,
		previousPage,
		nextPage,
		rows,
	} = useTable(
		{
			columns,
			data,
			globalFilter: filters ? globalFilterFunction : undefined,
			initialState: {pageSize: PAGE_SIZE},
		},
		useFlexLayout,
		useGlobalFilter,
		useSortBy,
		usePagination,
		useRowSelect,
		hooks => {
			if (onSelectChange) {
				hooks.visibleColumns.push(allColumns => [
					{
						id: "selection",
						width: 25,
						Header: ({getToggleAllPageRowsSelectedProps}) => {

							const {
								onChange,
								checked = false,
								...props
							} = getToggleAllPageRowsSelectedProps();

							return (
								<Checkbox
									{...props}
									checked={allSelected || checked}
									className={styles.checkbox}
									onChange={e => handleUncheckBox(e, onChange)}
								/>
							)
						},
						Cell: ({row}) => {

							const {
								onChange,
								checked,
								...props
							} = row.getToggleRowSelectedProps();

							return (
								<Checkbox
									{...props}
									checked={allSelected || checked}
									className={styles.checkbox}
									onChange={e => handleUncheckBox(e, onChange)}
								/>
							)
						},
					},
					...allColumns,
				]);
			}
		},
	);

	const countFrom = (pageIndex * pageSize) + 1;
	const countTo = (countFrom + pageSize - 1) > rows.length ?
		rows.length : (countFrom + pageSize) - 1;
	const rowsOnPage = countTo - countFrom + 1;

	useMountedLayoutEffect(() => {
		if (
			((selectedValues?.length === rows.length) && allSelected) ||
			((selectedValues?.length === selectedFlatRows.length) && !allSelected)
		) {
			return;
		}

		if (allSelected) {
			onSelectChange?.(rows);
		} else {
			onSelectChange?.(selectedFlatRows);
		}
	}, [selectedFlatRows.length, allSelected]);

	// If searching is available, updates filter for it.
	useEffect(() => setGlobalFilter(searchValue), [searchValue]);
	/**
	 * Not 100% efficient, but we want the checkboxes to reset whenever we do a big selection
	 * action. Whenever our passed in values is empty and the internal state of React Table is not,
	 * we need to reset selected rows.
	 */
	useMountedLayoutEffect(() => {
		if (selectedValues?.length !== 0 && selectedFlatRows.length === 0) toggleAllRowsSelected(false);
	}, [selectedValues]);

	// Whenever page changes, reset selected
	useMountedLayoutEffect(() => {
		if (selectedFlatRows.length) toggleAllRowsSelected(false);
		setAllSelected(false);
	}, [pageIndex]);

	return (
		<div className={styles.mainContainer}>
			<div className={styles.tableContainer}>
				<table {...getTableProps()} className={bStyles("table", className, {isDarkMode})}>
					<thead className={styles.header}>
						{headerGroups.map((headerGroup, i) => (
							<tr {...headerGroup.getHeaderGroupProps()} key={i}>
								{headerGroup.headers.map((column, j) => (
									<th {...column.getHeaderProps(column.getSortByToggleProps())} key={j}>
										<Caption type="medium" color="gray-modern-600" className={styles.tableThText}>
											{column.render("Header")}
											{column.isSorted ?

												column.isSortedDesc ?
												<ArrowDownIcon className={styles.arrowIcon} /> :
												<ArrowUpIcon className={styles.arrowIcon}/>
											: null}
											</Caption>
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody>
						{
							onSelectChange &&
						setAllSelected &&
						selectedFlatRows.length === rowsOnPage &&
						pageCount > 1 && (
								selectedValues?.length === selectedFlatRows.length ||
							selectedValues?.length === rows.length
							) && (
								<tr className={styles.selectionRow}>
									<td className={styles.selectAll}>
										<div>
											{allSelected ? <>
											All {rows.length} rows selected.
												<Button
													onClick={() => toggleAllRowsSelected(false)}
													variant="outlined"
													className={styles.smallButton}
												>
												Clear selection
												</Button>
											</> : <>
										Selected all {selectedValues?.length} rows on this page.
												<Button
													size="small"
													onClick={() => setAllSelected(true)}
													className={styles.smallButton}
												>
													{`Select all ${rows.length}`}
												</Button>
											</>
											}
										</div>
									</td>
								</tr>
							)}
						{page.map((row, i) => {
							prepareRow(row);
							return (
								<tr {...row.getRowProps()} key={i}>
									{row.cells.map((cell, j) => (
										<td {...cell.getCellProps()} key={j}>
											{cell.render("Cell", {editableRowIndex})}
										</td>
									))}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			<div className={styles.footer}>
				<span className={bStyles("rowCount", {isDarkMode})}>
					{rows.length > 0 ? `${countFrom} - ${countTo} of ${rows.length}` : "No records"}
				</span>
				<TablePagination
					currentPage={pageIndex}
					pageCount={pageCount}
					handleNext={nextPage}
					handlePrev={previousPage}
					gotoPage={gotoPage}
				/>
			</div>
		</div>
	);
};

export {Table};
