import { useEffect, useRef } from 'react';

/**
 * Custom hook to trigger a callback function repeatedly if a condition is met for a specified duration.
 *
 * @param {boolean} condition - The condition to monitor (e.g., a loading state).
 * @param {Function} callback - The function to invoke if the condition remains true for specified amount of seconds.
 * @param {number} duration - The duration to wait before invoking the callback function (default: 3000ms).
 * @param {number} maxCalls - The maximum number of times the callback function can be called. If the limit is below 0, the callback function will be called indefinitely (default: -1).
 */
export function useConditionalInterval(
  condition: boolean,
  callback: Function,
  duration = 3000,
  maxCalls = -1
) {
  const countRef = useRef(0);

  useEffect(() => {
    let interval;

    if (condition) {
      interval = setInterval(() => {
        if (maxCalls > 0 && countRef.current >= maxCalls) {
          clearInterval(interval);
          return;
        }
        countRef.current++;
        callback();
      }, duration);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [condition, callback, duration]);
}
