import classNames from "classnames/bind";
import {AnimatePresence, motion, useAnimationControls} from "framer-motion";
import React, {ReactElement, useEffect, useMemo, useRef, useState} from "react";

import {ChatActions} from "../../../canvas/chat-actions";
import {ChatConversationMessage} from "../../../models/ai-model";
import {Content} from "./content";
import {GroundingSection} from "./grounding-section";
import {isChatMessageLoading} from "../utils/isChatMessageLoading";
import {LoadingContent} from "./loading-content";
import {ResponseAvatar} from "./response-avatar";
import {SizeTransition} from "../../../shared/v2/size-transition";
import {Subheader} from "../../../shared/v2";
import {useChatConversationContext} from "@/context/chat-contexts";
import {useConditionalInterval} from "@/hooks";
import {useThemeMode} from "../../../context/theme-mode-context";
import {useUnsafeAgentCreationContext} from "../../../context/agent-creation-context";

import styles from "./response-bubble.module.scss";

const cx = classNames.bind(styles);

export interface ResponseBubbleProps {
  message: ChatConversationMessage;
}

export const ResponseBubble = ({message}: ResponseBubbleProps): ReactElement => {
	const {state: {voiceId: agentCreationVoiceId, name: agentCreationName} = {}} =
    useUnsafeAgentCreationContext() ?? {};
	const {refetch: refetchChatConversation} = useChatConversationContext();
	const isUnveilingRef = useRef(false);
	const [isOpacityLayerVisible, setIsOpacityLayerVisible] = useState(false);
	const animatedRef = useRef(false);
	const {isDarkMode} = useThemeMode();
	const groundingData = message.groundingData;

	const isLoading = useMemo(() => isChatMessageLoading(message), [message.id, message.content]);

	/**
	 * We may want to refetch the chat conversation every 30 seconds if the message is still loading.
	 * This is to ensure that the message is not stuck in a loading state.
	 */
	useConditionalInterval(
		isLoading,
		refetchChatConversation,
		30 * 1000, // refetch every 30 seconds
		5 // maximum of 5 refetches
	);

	const setIsUnveiling = (value: boolean) => {
		setIsOpacityLayerVisible(value);
		isUnveilingRef.current = value;
	}

	const responseBubbleControls = useAnimationControls();

	const transition = {
		duration: 0.15,
	}

	const responseBubbleVariants = {
		hidden: {
			opacity: 0,
			borderColor: "transparent",
		},
		show: {
			opacity: 1,
			transition,
		},
	}

	useEffect(() => {
		if (isChatMessageLoading(message)) {
			responseBubbleControls.set("hidden");
			responseBubbleControls.start("show");
			animatedRef.current = true;
		}
	}, [])

	useEffect(() => {
		if (animatedRef.current && !isLoading) {
			setIsUnveiling(true);
		}
	}, [isLoading])

	const personaName = useMemo(() => {
		if (message.persona?.name) {
			return message.persona.name;
		}

		if (agentCreationName) {
			return agentCreationName;
		}

		return "Vurvey";
	}, [message.persona, agentCreationName]);

	return (
		<div className={cx("responseBubbleWrapper", {isDarkMode})}>
			<SizeTransition
				active={isUnveilingRef.current}
				dimensions="height"
				onTransitionEnd={() => setIsUnveiling(false)}
				speed={200}
			>
				<motion.div
					animate={responseBubbleControls}
					aria-label="response-bubble"
					className={styles.responseBubble}
					variants={responseBubbleVariants}
				>
					<div className={styles.mainWrapper}>
						<ResponseAvatar message={message} />
						<div className={styles.contentWrapper}>
							{isLoading ? (
								<LoadingContent persona={message.persona} />
							) : (
								<>
									<Subheader type="semibold">
										{personaName}
									</Subheader>
									<Content message={message} />
									<ChatActions
										hideAudio={message.type === "image"}
										key={message.id}
										message={message}
										voiceId={agentCreationVoiceId}
									/>
								</>
							)}
						</div>
					</div>
					{groundingData && Boolean(groundingData.length) && (
						<GroundingSection grounding={groundingData} />
					)}
				</motion.div>
			</SizeTransition>
			<AnimatePresence>
				{isOpacityLayerVisible && (
					<motion.div
						className={styles.opacityOverlay}
						exit={{height: 0, transition}}
					/>
				)}
			</AnimatePresence>
		</div>
	);
};
