import React, {ReactElement} from "react";

import {AlignText} from "@/shared/typography/align-text";
import {Dropdown, DropdownItem} from "@/shared/v2";
import {BinDeleteIcon, DotsIcon, TagLabelIcon} from "@/icons";
import {TrainingSet, TrainingSetMedia} from "@/models/ai-model";
import {useWorkspaceContext} from "@/context/workspace-context";

import styles from "./file-actions.module.scss";

const isCSVorXLSX = (file: TrainingSetMedia): boolean => {
	const extension = file.originalFilename.split(".").pop()?.toLowerCase();
	return extension === "csv" || extension === "xlsx";
};

export interface FileActionsProps {
  file: TrainingSetMedia;
  trainingSet: TrainingSet;
  setFileForEditingLabels: (file: TrainingSetMedia) => void;
  setIsManageLabelsModalOpen: (isOpen: boolean) => void;
  setItemSelected: (file: TrainingSetMedia) => void;
}

export const FileActions = ({file, trainingSet, setFileForEditingLabels, setIsManageLabelsModalOpen, setItemSelected}: FileActionsProps): ReactElement => {
  const {workspace} = useWorkspaceContext();

  return <AlignText align="right">
    <Dropdown
      trigger={<DotsIcon className={styles.trigger} />}
      items={
        [
          isCSVorXLSX(file) &&
            trainingSet?.name && {
              label: "Open In Data Studio",
              onClick: () => {
                const url = `https://datastudio.vurvey.dev/?workspace_id=${workspace?.id}&file_id=${file?.id}&dataset_id=${trainingSet?.name}&file_name=${encodeURIComponent(file?.originalFilename)}`;
                window.open(url, "_blank");
              },
              color: "primary",
            },
            {
              label: "Edit Labels",
              icon: <TagLabelIcon />,
              onClick: () => {
                setFileForEditingLabels(file);
                setIsManageLabelsModalOpen(true);
              }
            },
            {
              label: "Delete",
              onClick: () => setItemSelected(file),
              color: "danger",
              icon: <BinDeleteIcon />,
            },

          ].filter(Boolean) as DropdownItem[]
        }
      position="bottom-start"
    />
  </AlignText>
}
