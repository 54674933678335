import React, {ReactElement} from "react";

import {AssistiveChip} from "@/shared/v2/assistive-chip";
import {Label} from "../../manage-labels-modal/labels-form";

import styles from "./file-labels.module.scss";

export interface FileLabelsProps {
  tags: Label[];
  onShowAll: () => void;
}

export const FileLabels = ({tags, onShowAll}: FileLabelsProps): ReactElement => {
  const tagsLength = tags?.length || 0;
  if (tagsLength === 0) {
    return <></>;
  }

  const tagsToShow = tags.slice(0, 2);

  return (
    <div className={styles.tags}>
      {tagsToShow.map((tag, index) => (
        <AssistiveChip key={index} text={`${tag.key}: ${tag.value}`} />
      ))}
      {tagsLength > 2 && (
        <AssistiveChip
          className={styles.displayMoreTags}
          style="outline"
          text={`+${tagsLength - 2}`}
          onClick={onShowAll}
        />
      )}
    </div>
  );
}
