import React, {ReactElement, useEffect, useState} from 'react';

import {BaseModalProps, Button, Input, Modal, TextArea} from '@/shared/v2';
import {useToastContext} from '@/context/toast-context';

import styles from './edit-key-modal.module.scss';

export interface EditKeyModalProps extends BaseModalProps {
  onSave: (key: string) => void;
  editedKey?: string;
}

export const EditKeyModal = ({isOpen, onClose, onSave, editedKey}: EditKeyModalProps): ReactElement => {
  const {updateToast} = useToastContext();


  const [keyToEdit, setKeyToEdit] = useState(editedKey || "");

  console.log("key", editedKey)
  console.log("keyToEdit", keyToEdit)

  useEffect(() => {
    if (editedKey) {
      setKeyToEdit(editedKey);
    }
  }, [editedKey, isOpen]);

  const handleClose = () => {
    // setNewKey(key);
    onClose();
  }

	const handleSave = ():void => {
    if (!keyToEdit) {
      return updateToast({
        description: "Key cannot be empty",
        type: "failure"
      })
    }

    onSave(keyToEdit)
    // if (!keys) {
    //   return updateToast({
    //     description: "You have to add at least one key",
    //     type: "failure"
    //   })
    // }
    // const keysArray = keys.split(",").map(key => key.trim()).filter(key => key !== "");
    // if (keysArray.length === 0) {
    //   return updateToast({
    //     description: "You have to add at least one valid key",
    //     type: "failure",
    //   });
    // }
    // onSave(keysArray);
    // handleClose();
	}

	return <Modal
		isOpen={isOpen}
		onClose={handleClose}
		title="Edit Key"
    size='small'
	>
		<div className={styles.form}>
			<Input
        value={keyToEdit}
        onChange={setKeyToEdit}
        label='Key'
      />
		</div>

		<div className={styles.footer}>
			<Button variant="outlined" onClick={handleClose}>Cancel</Button>
			<Button onClick={handleSave}>Save</Button>
		</div>
	</Modal>
}
