import React, {ReactElement, useState} from 'react';

import {BaseModalProps, Button, Modal, TextArea} from '@/shared/v2';
import {useToastContext} from '@/context/toast-context';

import styles from './add-keys-modal.module.scss';

export interface AddKeysModalProps extends BaseModalProps {
  onSave: (keys: string[]) => void;
}

export const AddKeysModal = ({isOpen, onClose, onSave}: AddKeysModalProps): ReactElement => {
  const {updateToast} = useToastContext();


  const [keys, setKeys] = useState("");

  const handleClose = () => {
    setKeys("");
    onClose();
  }

	const handleSave = ():void => {
    if (!keys) {
      return updateToast({
        description: "You have to add at least one key",
        type: "failure"
      })
    }
    const keysArray = keys.split(",").map(key => key.trim()).filter(key => key !== "");
    if (keysArray.length === 0) {
      return updateToast({
        description: "You have to add at least one valid key",
        type: "failure",
      });
    }
    onSave(keysArray);
    handleClose();
	}

	return <Modal
		isOpen={isOpen}
		onClose={handleClose}
		title="Create Keys"
    size='small'
	>
		<div className={styles.form}>
			<TextArea
				value={keys}
				onChange={setKeys}
				placeholder="Key1, Key2, Key3"
        label='Add as many keys as you want, separated by a comma'
			/>
		</div>

		<div className={styles.footer}>
			<Button variant="outlined" onClick={handleClose}>Cancel</Button>
			<Button onClick={handleSave}>Save</Button>
		</div>
	</Modal>
}
