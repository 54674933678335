import React, {ReactElement, useContext, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router";
import {useMutation, useQuery} from "@apollo/client";
import {Column, Row} from "react-table";

import {GET_TRAINING_SET} from "../../../graphql/queries/ai-models-queries";
import {
	DELETE_TRAINING_SET_FILE,
	DELETE_TRAINING_SET_VIDEO,
	REMOVE_FILE_FROM_TRAINING_SET,
	REMOVE_VIDEO_FROM_TRAINING_SET,
	RETRY_FAILED_FILE_TRANSCODING,
	SET_TAGS_TO_TRAINING_SET_MEDIA,
} from "../../../graphql/mutations/ai-mutations";
import {NavLink} from "../../../route/components";
import {FileEmbeddingsGenerationStatus, TrainingSetMedia, TrainingSetVideo} from "../../../models/ai-model";
import {BinDeleteIcon, ChevronLeftIcon, DotsIcon, ReloadArrowIcon, SparkAiStarsIcon, TagLabelIcon} from "../../../icons";
import {Table} from "../../../shared/components/table";
import {AlignText} from "../../../shared/typography/align-text";
import {FileUploader} from "../../components";
import {ToastContext} from "../../../context/toast-context";
import {
	Button,
	SearchInput,
	Body,
	Subheader,
	LoadingContainer,
	Tooltip,
	Dropdown,
	DropdownItem,
	ButtonIcon,
	Separator,
} from "../../../shared/v2";
import {useNavigate} from "../../../route";
import {ConfirmActionModal} from "../../../shared/v2/modals/confirm-action-modal";
import {useMount} from "@/hooks/useMount";
import {Badge} from "@/workspace-settings/components/model-card/badge";
import {ManageLabelsModal} from "@/datasets/components/manage-labels-modal";
import {cache} from "@/cache";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {AssistiveChip} from "@/shared/v2/assistive-chip";
import {ReplaceExistingLabelsModal} from "@/datasets/components/modals";
import {Label} from "@/datasets/components/manage-labels-modal/labels-form";

import styles from "./dataset-page.module.scss";
import {FileActions} from "@/datasets/components/dataset-page/file-actions";
import {FileStatus} from "@/datasets/components/dataset-page/file-status";
import {FileLabels} from "@/datasets/components/dataset-page/file-labels";
import {vi} from "date-fns/locale";

export const POLL_INTERVAL = 30000;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isTrainingVideo = (m: any): m is TrainingSetVideo => m.transcodingStatus !== undefined;



export interface RetryFailedFileTranscodingResult {
	retryFailedFileTranscoding: {
		id: string;
		embeddingsGenerationStatus: FileEmbeddingsGenerationStatus;
	};
}

export const DatasetPage = (): ReactElement => {
	const {trainingSetId} = useParams();
	const navigate = useNavigate();
	const {updateToast} = useContext(ToastContext);
	const [itemSelected, setItemSelected] = useState<TrainingSetMedia | undefined>();
	const [showBulkDelete, setShowBulkDelete] = useState(false);
	const {workspace} = useWorkspaceContext();
	const [shouldPoll, setShouldPoll] = useState(false);
	const [isManageLabelsModalOpen, setIsManageLabelsModalOpen] = useState(false);
	const [isReplacingLabelsWaringModalOpen, setIsReplacingLabelsWaringModalOpen] = useState(false);
	const [fileForEditingLabels, setFileForEditingLabels] = useState<TrainingSetMedia | undefined>();

	const [saveLabels] = useMutation(SET_TAGS_TO_TRAINING_SET_MEDIA, {
		onError: () => {
			updateToast({
				type: "failure",
				description: "Failed to save labels, please try again later",
			});
		}
	});

	const {
		data: {trainingSet} = {},
		loading,
		startPolling,
		stopPolling,
	} = useQuery(GET_TRAINING_SET, {
		variables: {trainingSetId},
		fetchPolicy: "network-only",
	});

	useMount(() => {document.title = "Vurvey - Datasets"})

	useEffect(() => {
		if (trainingSet) {
			if (allHasEmbeddings) {
				setShouldPoll(false);
				stopPolling();

				if (shouldPoll) {
					updateToast({
						description: "All files are ready. You can start a conversation now.",
						type: "success",
					});
				}
			}
		}
	}, [trainingSet, stopPolling]);

	const [deleteFile] = useMutation(DELETE_TRAINING_SET_FILE);
	const [deleteVideo] = useMutation(DELETE_TRAINING_SET_VIDEO);
	const [removeFileFromTrainingSet] = useMutation(REMOVE_FILE_FROM_TRAINING_SET, {
		refetchQueries: [GET_TRAINING_SET],
	});
	const [removeVideoFromTrainingSet] = useMutation(REMOVE_VIDEO_FROM_TRAINING_SET, {
		refetchQueries: [GET_TRAINING_SET],
	});
	const [retryTranscoding] = useMutation<RetryFailedFileTranscodingResult>(RETRY_FAILED_FILE_TRANSCODING);

	const [searchValue, setSearchValue] = useState("");
	const [selectedFiles, setSelectedFiles] = useState<Row<TrainingSetMedia>[]>([]);

	const trainingSetFiles = useMemo(() => {
		return trainingSet?.files?.concat(trainingSet?.videos || []);
	}, [trainingSet?.files, trainingSet?.videos]);

	const allHasEmbeddings = useMemo(() => {
		return trainingSetFiles?.every(file => file.embeddingsGenerationStatus === FileEmbeddingsGenerationStatus.SUCCESS);
	}, [trainingSetFiles]);

	const filteredFiles = useMemo(() => {
		if (!trainingSetFiles) return [];

		return trainingSetFiles.filter(file => {
			return file.originalFilename.toLowerCase().includes(searchValue.toLowerCase());
		});
	}, [trainingSetFiles, searchValue]);

	const handleDeleteFile = (file?: TrainingSetMedia): void => {
		if (!file) return;
		if (isTrainingVideo(file)) {
			deleteVideo({
				variables: {videoId: file.id},
				onError: () =>
					updateToast({
						type: "failure",
						description: "Failed to delete, please try again later",
					}),
				onCompleted: data => {
					if (!data.deleteTrainingSetVideo) {
						return updateToast({
							type: "failure",
							description: "Failed to delete, please try again later",
						});
					}
					removeVideoFromTrainingSet({
						// Only returns a boolean, probably should update API
						variables: {videoId: file.id, trainingSetId},
						onCompleted: () =>
							updateToast({
								description: "File deleted",
								type: "informational",
							}),
						onError: () =>
							updateToast({
								type: "failure",
								description: "File deleted but it will take time to reflect here",
							}),
					});
				},
			});
			return setItemSelected(undefined);
		}
		deleteFile({
			variables: {fileId: file.id},
			onError: () =>
				updateToast({
					type: "failure",
					description: "Failed to delete, please try again later",
				}),
			onCompleted: data => {
				if (!data.deleteTrainingSetFile) {
					return updateToast({
						type: "failure",
						description: "Failed to delete, please try again later",
					});
				}
				removeFileFromTrainingSet({
					// Only returns a boolean, probably should update API
					variables: {fileId: file.id, trainingSetId},
					onCompleted: () => updateToast({description: "File deleted", type: "informational"}),
					onError: () =>
						updateToast({
							type: "failure",
							description: "File deleted but it will take time to reflect here",
						}),
				});
			},
		});
		setItemSelected(undefined);
	};

	const handleDeleteSelectedFiles = (): void => {
		selectedFiles.forEach(file => {
			handleDeleteFile(file.original);
		});
		setShowBulkDelete(false);
		setSelectedFiles([]);
	};

	const updateMediaTranscodingStatus = (file: TrainingSetMedia, status: FileEmbeddingsGenerationStatus): void => {
		cache.writeQuery({
			query: GET_TRAINING_SET,
			variables: {trainingSetId},
			data: {
				trainingSet: {
					...trainingSet,
					...(
						isTrainingVideo(file)
							? {
								videos: trainingSet.videos.map(v => (v.id === file.id ? {...v, embeddingsGenerationStatus: status} : v)),
							}
							: {
								files: trainingSet.files.map(f => (f.id === file.id ? {...f, embeddingsGenerationStatus: status} : f)),
							}
					)
				},
			},
		});
	}

	const handleRetryTranscoding = async (file: TrainingSetMedia) => {
		updateMediaTranscodingStatus(file, FileEmbeddingsGenerationStatus.PROCESSING);

		await retryTranscoding({
			variables: {
				trainingSetId,
				...(isTrainingVideo(file) ? {videoId: file.id} : {fileId: file.id}),
			},
			onCompleted: ({retryFailedFileTranscoding: {embeddingsGenerationStatus: status}}) => {
				updateMediaTranscodingStatus(file, status);
			},
			onError: () => {
				updateToast({
					type: "failure",
					description: "Failed to retry transcoding, please try again later",
				});
				updateMediaTranscodingStatus(file, FileEmbeddingsGenerationStatus.FAILED);
			}
		});
	};

	const columns = useMemo(
		(): Column<TrainingSetMedia>[] => [
			{
				Header: "Name",
				accessor: "originalFilename",
				Cell: data => {
					const file = data.row.original;
					return (
						<a href={file.url} target="_blank" className={styles.fileLink} rel="noreferrer">
							<Body size="s" type="medium" className={styles.overflow}>
								{file.originalFilename}
							</Body>
						</a>
					);
				},
			},
			{
				Header: "Type",
				accessor: "originalMimetype",
				Cell: data =>
					data.value && (
						<Body size="s" className={styles.overflow} color="text-tertiary">
							{data.value}
						</Body>
					),
			},
			{
				Header: "Status",
				accessor: "embeddingsGenerationStatus",
				Cell: data => {
					const row = data.row.original;

					return <FileStatus row={row} handleRetryTranscoding={handleRetryTranscoding} />;
				},
			},
			{
				Header: "Labels",
				Cell: data => {
					const row = data.row.original;
					const tags = row.tags;

					const handleShowAll = () => {
						setFileForEditingLabels(row);
						setIsManageLabelsModalOpen(true);
					};

					return <FileLabels
						tags={tags}
						onShowAll={handleShowAll}
					/>
				}
			},
			{
				id: "options",
				Header: "",
				maxWidth: 50,
				disableSortBy: true,
				Cell: data => {
					const file = data.row.original;

					return <FileActions
						file={file}
						trainingSet={trainingSet}
						setFileForEditingLabels={setFileForEditingLabels}
						setIsManageLabelsModalOpen={setIsManageLabelsModalOpen}
						setItemSelected={setItemSelected}
					/>
				},
			},
		],
		[workspace.id, trainingSetId, trainingSet],
	);

	const openInCopilot = (): void => {
		navigate(
			{
				pathname: "/workflow/conversation",
				search: {trainingSetIds: [trainingSet.id]},
			},
			{workspace: true},
		);
	};

	const handleStartPolling = (): void => {
		setShouldPoll(true);
		startPolling(POLL_INTERVAL);
	};

	const selectedTags = useMemo(() => {
		if (fileForEditingLabels) {
			return fileForEditingLabels.tags || [];
		}

		if (!selectedFiles.length) {
			return [];
		}
		if (selectedFiles.length === 1) {

			return selectedFiles[0].original.tags || [];
		}
		return [];
	}, [selectedFiles.length, fileForEditingLabels]);

	const handleAddLabel = () => {
		if (selectedFiles.length === 0) {
			return;
		}

		if (selectedFiles.length === 1) {
			return setIsManageLabelsModalOpen(true);
		}

		if (selectedFiles.some(file => file.original.tags?.length > 0)) {
			return setIsReplacingLabelsWaringModalOpen(true);
		}
		setIsManageLabelsModalOpen(true);
	}

	const closeLabelsModal = () => {
		setFileForEditingLabels(undefined);
		setIsManageLabelsModalOpen(false);
	}

	const handleSaveLabels = (labels: Label[]) => {
		if (fileForEditingLabels) {
			const isVideo = isTrainingVideo(fileForEditingLabels);

			if (isVideo) {
				saveLabels({
					variables: {
						trainingSetId: trainingSetId,
						media: {
							fileIds: [],
							videoIds: [fileForEditingLabels.id],
						},
						tags: labels.map(label => ({key: label.key, value: label.value})),
					}
				})
				setIsManageLabelsModalOpen(false);
				return;
			} else {
				saveLabels({
					variables: {
						trainingSetId: trainingSetId,
						media: {
							fileIds: [fileForEditingLabels.id],
							videoIds: [],
						},
						tags: labels.map(label => ({key: label.key, value: label.value})),
					}
				})
				setIsManageLabelsModalOpen(false);
				return;
			}
		}

		saveLabels({
			variables: {
				trainingSetId,
				media: {
					fileIds: selectedFiles.filter(file => !isTrainingVideo(file.original)).map(file => file.original.id),
					videoIds: selectedFiles.filter(file => isTrainingVideo(file.original)).map(file => file.original.id),
				},
				tags: labels.map(label => ({key: label.key, value: label.value})),
			}
		})
		setIsManageLabelsModalOpen(false);
	}

	if (loading) {
		return <LoadingContainer />;
	}

	return (
		<>
			<div className={styles.container}>
				<NavLink to="/datasets" className={styles.backArrowWrapper} workspace omitSearch>
					<ChevronLeftIcon className={styles.backIcon} />
				</NavLink>
				<div className={styles.introSection}>
					<div className={styles.introLeftSection}>
						<Subheader size="xl" type="medium">
							{trainingSet?.alias}
						</Subheader>
						<Body className={styles.description} type="regular" color="text-secondary">
							{trainingSet?.description}
						</Body>
					</div>
					<div className={styles.introRightSection}>
						<Tooltip
							content={
								<Body size="xs">
									All files and videos must have been processed before starting conversation
								</Body>
							}
						>
							<Button
								disabled={!allHasEmbeddings || !trainingSetFiles?.length}
								style="ai"
								onClick={openInCopilot}
								leftIcon={<SparkAiStarsIcon />}
								size="small"
							>
								Conversation
							</Button>
						</Tooltip>
						<FileUploader
							trainingSet={trainingSet}
							refetchQuery={GET_TRAINING_SET}
							startPolling={handleStartPolling}
						/>
					</div>
				</div>

				<Separator color="workflow" />

				<div className={styles.tableActions}>
					<SearchInput className={styles.searchInput} value={searchValue} onChange={setSearchValue} />
					<div className={styles.actionsWrapper}>
						{selectedFiles.length > 0 &&
							<div className={styles.actionButtons}>
								<Button
									size="small"
									variant="outlined"
									leftIcon={<TagLabelIcon />}
									onClick={handleAddLabel}
								>
									Add Label
								</Button>

								<Button
									size="small"
									style="danger"
									variant="outlined"
									leftIcon={<BinDeleteIcon />}
									onClick={() => setShowBulkDelete(true)}
								>
									Delete
								</Button>
							</div>
						}
					</div>
				</div>

				<Table
					columns={columns}
					data={filteredFiles}
					selectedValues={selectedFiles}
					onSelectChange={setSelectedFiles}
				/>
				<ConfirmActionModal
					title="Delete File"
					isOpen={Boolean(itemSelected)}
					onClose={() => setItemSelected(undefined)}
					onConfirm={() => handleDeleteFile(itemSelected)}
					description="Are you sure you want to delete this file?"
				/>
				<ConfirmActionModal
					title="Delete Files"
					isOpen={showBulkDelete}
					onClose={() => setShowBulkDelete(false)}
					onConfirm={() => handleDeleteSelectedFiles()}
					description="Are you sure you want to delete these files? (May take some time to fully delete)"
				/>

				{isManageLabelsModalOpen &&
					<ManageLabelsModal
						onClose={closeLabelsModal}
						isOpen={isManageLabelsModalOpen}
						tags={selectedTags}
						onSave={handleSaveLabels}
						file={fileForEditingLabels}
					/>
				}

				<ReplaceExistingLabelsModal
					onClose={() => setIsReplacingLabelsWaringModalOpen(false)}
					isOpen={isReplacingLabelsWaringModalOpen}
					onConfirm={() => {
						setIsReplacingLabelsWaringModalOpen(false);
						setIsManageLabelsModalOpen(true)
					}}
				/>
			</div>
		</>
	);
};
