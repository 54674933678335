import React, {ReactElement, useState} from "react";
import {BaseModalProps, Body, Button, Input, Modal, TextArea, Checkbox, Spinner} from "../../../shared/v2";
import {useMutation} from "@apollo/client";

import {DocumentsFolderFileIcon, HornMegaphoneCampaignIcon, PlusSmallIcon} from "../../../icons";
import {useValidation} from "../../../hooks/useValidation";
import {CreateAiOrchestrationData, CreateAiOrchestrationVars} from "../../../models/ai-orchestration";
import {CREATE_AI_ORCHESTRATION} from "../../../graphql/mutations/ai-mutations";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {GET_AI_ORCHESTRATIONS} from "../../../graphql/queries/ai-orchestration-queries";
import {SelectSourcesModal} from "../select-sources-modal";
import {Campaign, TrainingSet} from "../../../models/ai-model";
import {isTrainingSet} from "../../../canvas/chat-bubble/sources-section";
import {InputChip} from "../../../shared/components/input-chip";
import {useToastContext} from "../../../context/toast-context";

import styles from "./create-workflow-modal.module.scss";

export interface CreateWorkflowModalProps extends BaseModalProps {
	onAdd: (id: string) => void;
}

export const CreateWorkflowModal = ({isOpen, onClose, onAdd}: CreateWorkflowModalProps): ReactElement => {
	const {updateToast} = useToastContext();
	const {
		workspace: {id: workspaceId},
	} = useWorkspaceContext();
	const [changes, setChanges] = useState({
		name: "",
		objective: "",
	});

	const [isSourcesModalOpen, setIsSourcesModalOpen] = useState(false);
	const [sources, setSources] = useState<(Campaign | TrainingSet)[]>([]);

	const [createWorkflow, {loading: isCreating}] = useMutation<CreateAiOrchestrationData, CreateAiOrchestrationVars>(
		CREATE_AI_ORCHESTRATION,
		{
			refetchQueries: [GET_AI_ORCHESTRATIONS],
		},
	);

	const {
		results: validation,
		validate,
		clearErrors,
	} = useValidation(changes, {
		name: {
			ruleset: {
				required: true,
				max: 150,
			},
		},
		objective: {
			ruleset: {
				required: true,
			},
		},
	});

	const [autoWorkflow, setAutoWorkflow] = useState(true);

	const handleChangeField = (value: string, field: string): void => {
		setChanges({
			...changes,
			[field]: value,
		});
	};

	const handleSave = (): void => {
		if (!validate()) {
			return;
		}


		createWorkflow({
			variables: {
				workspaceId,
				name: changes.name,
				description: changes.objective,
				trainingSetIds: sources.filter(isTrainingSet).map(s => s.id),
				surveyIds: sources.filter(s => !isTrainingSet(s)).map(s => s.id),
				autoWorkflow,
			},
			onError: () => {
				updateToast({
					description: "Failed to create workflow, please try again later.",
					type: "failure",
				});
			},
			onCompleted: data => {
				handleClose();
				onAdd(data.createAiOrchestration.id);
			},
		});
	};

	const handleClose = (): void => {
		setChanges({
			name: "",
			objective: "",
		});
		clearErrors();
		onClose();
	};

	const handleSelectSources = (campaings: Campaign[], trainingSets: TrainingSet[]): void => {
		const sources = [...campaings, ...trainingSets];
		setSources(sources);
	};

	const handleRemoveSource = (source: TrainingSet | Campaign): void => {
		setSources(sources.filter(s => s.id !== source.id));
	};

	const renderSource = (source: TrainingSet | Campaign) => {
		if (isTrainingSet(source)) {
			return (
				<InputChip
					icon={<DocumentsFolderFileIcon />}
					key={source.id}
					onRemove={() => handleRemoveSource(source)}
					label={source.alias}
					variant="squared"
					size="small"
				/>
			);
		}

		return (
			<InputChip
				variant="squared"
				icon={<HornMegaphoneCampaignIcon />}
				key={source.id}
				onRemove={() => handleRemoveSource(source)}
				label={source.name}
				size="small"
			/>
		);
	};

	return (
		<>
			<Modal
				size="medium"
				isOpen={isOpen}
				onClose={handleClose}
				title="Create New Workflow"
				className={styles.modal}
			>
				{isCreating ?
					<Spinner className={styles.spinner} /> :
					<div className={styles.form}>
						<Input
							placeholder="My workflow"
							label="Workflow Name"
							value={changes.name}
							onChange={(value: string) => handleChangeField(value, "name")}
							validation={validation.name}
						/>

						<div className={styles.objectiveWrapper}>
							<div className={styles.textContainer}>
								<Body color="text-secondary" size="s" type="medium">
									Objective
								</Body>
								<Body color="text-tertiary" size="s" type="regular">
									Define the overall objective for this workflow. This doesn&apos;t need to be highly
									specific, as you&apos;ll be able to assign detailed tasks to each agent later on.
								</Body>
							</div>
							<TextArea
								placeholder="e.g., Enhance marketing insights or Analyze customer feedback"
								value={changes.objective}
								onChange={(value: string) => handleChangeField(value, "objective")}
								validation={validation.objective}
							/>
						</div>

						<div className={styles.sourcesWrapper}>
							<div className={styles.textContainer}>
								<Body color="text-secondary" size="s" type="medium">
									Sources
								</Body>
								<Body color="text-tertiary" size="s" type="regular">
									Link appropriate sources for your workflow. The entire workflow will have access to
									these added sources, such as campaigns or datasets, to ensure comprehensive data
									integration.
								</Body>
							</div>

							{sources.length > 0 && <div className={styles.sourcesSection}>{sources.map(renderSource)}</div>}

							<Button
								className={styles.addSource}
								leftIcon={<PlusSmallIcon />}
								variant="text"
								onClick={() => setIsSourcesModalOpen(true)}
							>
								Add source
							</Button>
						</div>

						<Checkbox
							text="Auto Workflow"
							checked={autoWorkflow}
							onChange={e => setAutoWorkflow(e.target.checked)}
							className={styles.checkboxText}
						/>

						<div className={styles.footer}>
							<Button className={styles.button} variant="outlined" onClick={handleClose}>
								Cancel
							</Button>

							<Button className={styles.button} onClick={handleSave}>
								Create Workflow
							</Button>
						</div>
					</div>
				}

			</Modal>

			<SelectSourcesModal
				isOpen={isSourcesModalOpen}
				onClose={() => setIsSourcesModalOpen(false)}
				currentSourceIds={sources.map(s => s.id)}
				onSelect={handleSelectSources}
			/>
		</>
	);
};
