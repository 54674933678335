import React, {ReactElement} from "react";

import {BaseModalProps, Button, Modal} from "@/shared/v2";

import styles from "./confirm-logout-modal.module.scss";

export interface ReplaceExistingLabelsModalProps extends BaseModalProps {
	onConfirm: () => void;
}

export const ReplaceExistingLabelsModal = ({
	isOpen,
	onClose,
	onConfirm,
}: ReplaceExistingLabelsModalProps): ReactElement => {
	return <Modal
		size="x-small"
		isOpen={isOpen}
		onClose={onClose}
		title="Replace Existing Labels?"
		description="Some of the selected files already have labels. Adding new labels will overwrite the existing ones. Do you want to proceed?"
	>
		<div className={styles.footer}>
			<Button
				variant="outlined"
				className={styles.button}
				onClick={onClose}
			>
				Cancel
			</Button>

			<Button
				className={styles.button}
				onClick={onConfirm}
			>
        Replace labels
			</Button>
		</div>
	</Modal>;
};
