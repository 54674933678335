import React, {ReactElement, useState} from "react";

import {ButtonIcon} from "../../../buttons";
import {BinDeleteIcon, FileTextIcon, FileVideoIcon, TagLabelIcon} from "../../../../../icons";
import {Caption} from "../../../typography";
import {ProgressBar} from "../../../progress-bar";
import {FileWithUploadResult} from "../../../../../models/ai-model";
import {ConfirmActionModal} from "../../confirm-action-modal";
import {ManageLabelsModal} from "@/datasets/components/manage-labels-modal";
import {Label} from "@/datasets/components/manage-labels-modal/labels-form";
import {AssistiveChip} from "@/shared/v2/assistive-chip";

import styles from "./file-item.module.scss";
import {FileLabels} from "@/datasets/components/dataset-page/file-labels";

export interface FileItemProps {
	file: FileWithUploadResult;
	onDelete: (file: FileWithUploadResult) => void;
}

export const FileItem = ({file, onDelete}: FileItemProps): ReactElement => {
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isManageLabelsModalOpen, setIsManageLabelsModalOpen] = useState(false);

	const fileTypeIcon = file.type?.includes("video") ?
		<FileVideoIcon className={styles.typeIcon} /> :
		<FileTextIcon className={styles.typeIcon} />;

	const fileSize = (file.size / (1024 * 1024)).toFixed(2);

	const handleDelete = ():void => {
		setIsDeleteModalOpen(false);
		onDelete(file);
	};

	const handleSaveLabels = (labels: Label[]) => {
		file.labels = labels;
		setIsManageLabelsModalOpen(false);
	}

	return <>
		<div className={styles.wrapper}>
			<div className={styles.leftSideWrapper}>
				<div className={styles.infoWrapper}>
					{fileTypeIcon}
					<div className={styles.nameWrapper}>
						<Caption
							size="l"
							color="text-body"
							className={styles.name}
						>
							{file.name}
						</Caption>
						<Caption color="charcoal-elevation-100">
							{fileSize} Mb
						</Caption>
					</div>
				</div>

				{file.uploadResult ?
					<Caption type="semibold" color="lime-600" className={styles.complete}>
            Complete
					</Caption> :
					<ProgressBar />
				}
			</div>
			{file.labels &&
				<div className={styles.centerSideWrapper}>
					<FileLabels tags={file.labels} onShowAll={() => setIsManageLabelsModalOpen(true)} />
				</div>
			}
			<div className={styles.rightSideWrapper}>
				<ButtonIcon
					icon={<TagLabelIcon className={styles.tagIcon} />}
					onClick={() => setIsManageLabelsModalOpen(true)}
				/>

				<ButtonIcon
					icon={<BinDeleteIcon className={styles.deleteIcon} />}
					onClick={() => setIsDeleteModalOpen(true)}
				/>
			</div>
		</div>

		{isManageLabelsModalOpen &&
			<ManageLabelsModal
				onClose={() => setIsManageLabelsModalOpen(false)}
				isOpen={isManageLabelsModalOpen}
				onSave={handleSaveLabels}
				tags={file.labels}
			/>
		}

		<ConfirmActionModal
			isOpen={isDeleteModalOpen}
			onClose={() => setIsDeleteModalOpen(false)}
			onConfirm={handleDelete}
			title="Delete File"
			description="Are you sure you want to delete this file? This action cannot be undone."
			confirmText="Delete File"
		/>
	</>;
};
