import React, {ReactElement, useMemo, useState} from "react";

import {BaseModalProps, Button, Modal} from "@/shared/v2";
import {Label, LabelsForm} from "./labels-form";
import {TrainingSetMedia} from "@/models/ai-model";
import {useToastContext} from "@/context/toast-context";

import styles from "./manage-labels-modal.module.scss";

export interface ManageLabelsModalProps extends BaseModalProps {
	tags?: Label[];
	onSave: (labels: Label[]) => void;
	file?: TrainingSetMedia;
}

export const ManageLabelsModal = ({
	isOpen,
	onClose,
	tags,
	onSave,
	file
}: ManageLabelsModalProps): ReactElement => {
	const {updateToast} = useToastContext();
	const [labels, setLabels] = useState<Label[]>(tags || []);

	const handleSave = () => {
		if (labels.some(label => !label.key || !label.value)) {
			updateToast({
				description: "Label key and value are required",
				type: "failure"
			})
			return;
		}
		onSave(labels)
	}

	return <Modal
		headerWrapperClassName={styles.header}
		className={styles.manageLabelsModal}
    size="medium"
		isOpen={isOpen}
		onClose={onClose}
		title={
			<div className={styles.title}>
				<span>Labels</span>

				{file &&
					<>
						<span> for [</span>
						<span className={styles.fileName}>{file?.originalFilename}</span>
						<span>]</span>
					</>
				}

			</div>
		}
		description="Easily organize your files with key-value labels to improve search and categorization. For example, use Type: Report or Department: Marketing."
	>
		<div className={styles.modalBody}>
    	<LabelsForm labels={labels} setLabels={setLabels} />
		</div>
		<div className={styles.footer}>
      <Button
				onClick={onClose}
        variant="outlined"
      >
        Cancel
      </Button>
      <Button
				onClick={handleSave}
      >
        Save
      </Button>
		</div>
	</Modal>;
};
