import classNames from "classnames/bind";
import React, {ReactElement, useEffect, useState} from "react";

import {Body} from "../../../../shared/v2";
import {ConversationEventTypes} from "../../../../models/subscriptions";
import {Persona} from "../../../../models";
import {useChatEventsContext} from "../../../../context/chat-contexts";
import {useThemeMode} from "../../../../context/theme-mode-context";
import {useUnsafeAgentCreationContext} from "../../../../context/agent-creation-context";

import styles from "./loading-content.module.scss";

const cx = classNames.bind(styles);

export interface LoadingContentProps {
  persona?: Persona;
}

export const LoadingContent = ({persona}: LoadingContentProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const {
		state: {
			name: agentCreationName = undefined,
		} = {}
	} = useUnsafeAgentCreationContext() ?? {};
	const {events: {
		[ConversationEventTypes.RESPONSE_STATE_CHANGED]: responseStateChanges,
	}, removeEvent} = useChatEventsContext();
	const [content, setContent] = useState<string>(`${persona?.name || agentCreationName || "V"} is generating`);

	useEffect(() => {
		if (responseStateChanges.length > 0) {
			const event = responseStateChanges[0]
			const {data: {content}} = responseStateChanges[0];

			setContent(content);
			removeEvent(event);
		}
	}, [responseStateChanges, persona, agentCreationName, removeEvent]);

	return (
		<Body className={cx("loadingWrapper", {isDarkMode})}>
			<span>
				{content}
			<span className={styles.loadingIcon}></span>

			</span>
		</Body>
	);
}
